import React from 'react'
import { useGlobal } from 'reactn'

import './index.css'

import NumberFormat from 'react-number-format'
import EditStreams from '../edit-streams'

const SliderSummary = () => {
  const [gross] = useGlobal('gross')
  const [recoupableAmount] = useGlobal('recoupable-amount')
  const [dealType] = useGlobal('deal-type')
  return (
    <div className="flex-row flex-center space-between full-width resp-column-reverse" id="slider-summary-container" style={ dealType.value === 'self' ? { position: 'relative', top: '.75rem' } : null }>
      <div id="slider-summary" className="flex-one flex space-between">
        <div className="flex-row align-center" id="ssc-1">
          <label className="label-1 weight-800">streaming revenue:&nbsp;</label>
          <NumberFormat className="summary-number" value={gross} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </div>
        {dealType.value !== 'self' && (
          <div className="flex-row align-center resp-column">
            <label className="label-1 resp-advance flex weight-800">Advance:&nbsp;</label>
            <NumberFormat className={`summary-number ${recoupableAmount === 0 && 'red-text'}`} value={recoupableAmount} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </div>
        )}
      </div>
      { dealType.value === 'self' && <EditStreams /> }
    </div>
  )
}

export default SliderSummary