import React, { useEffect, useState } from 'react'
import { useGlobal } from 'reactn'
import './index.css'

import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import RateSlider from '../../revenue/rate-slider'

const Split = ({ type, className, handleEdit }) => {

  // global state

  const [songwriterPerfSplit, setSongwriterPerfSplit] = useGlobal('songwriter-perf-split')
  const [publisherPerfSplit, setPublisherPerfSplit] = useGlobal('publisher-perf-split')
  const [songwriterMechSplit, setSongwriterMechSplit] = useGlobal('songwriter-mech-split')
  const [publisherMechSplit, setPublisherMechSplit] = useGlobal('publisher-mech-split')

  // local state

  const getSongwriterRoyalty = () => {
    if(type === 'mech') return songwriterMechSplit
    if(type === 'perf') return songwriterPerfSplit
  }

  const getPublisherRoyalty = () => {
    if(type === 'mech') return publisherMechSplit
    if(type === 'perf') return publisherPerfSplit
  }

  const [open, setOpen] = useState(false)
  const [explanation, setExplanation] = useState('')
  const [songwriterRoyalty, setSongwriterRoyalty] = useState(getSongwriterRoyalty())
  const [publisherRoyalty, setPublisherRoyalty] = useState(getPublisherRoyalty())

  // listen for changes globally so, e.g., if changed by change of deal type, reflected here

  useEffect(() => {
    if(type === 'perf') {
      setSongwriterRoyalty(songwriterPerfSplit)
      setPublisherRoyalty(100 - songwriterPerfSplit)
      if(publisherPerfSplit !== (100 - songwriterPerfSplit)) {
        // here and below, only set if it was changed by another event, 
        // e.g, based on reading a query string on landing
        setPublisherPerfSplit(100 - songwriterPerfSplit)
      }
    }
  }, [songwriterPerfSplit])

  useEffect(() => {
    if(type === 'mech') {
      setSongwriterRoyalty(songwriterMechSplit)
      setPublisherRoyalty(100 - songwriterMechSplit)
      if(publisherMechSplit !== (100 - songwriterMechSplit)) {
        setPublisherMechSplit(100 - songwriterMechSplit)
      }
    }
  }, [songwriterMechSplit])

  // accessibility
  
  function valuetext(value) {
    return `${value}`;
  }

  // constants

  const label = type === 'perf'
    ? <>Digital Perf.<br /> Royalty Split</>
    : <>Digital Mech.<br />Royalty Split</>

  // handlers

  const handleChange = (e, v) => {
    let value = v
    setSongwriterRoyalty(value)
    setPublisherRoyalty(100 - value)
    if(type === 'perf') {
      setSongwriterPerfSplit(value)
      setPublisherPerfSplit(100 - value)
    }
    if(type === 'mech') {
      setSongwriterMechSplit(value)
      setPublisherMechSplit(100 - value)
    }
  }

  const handleChangeCommitted = () => {
    const value = type === 'perf' ? songwriterPerfSplit : songwriterMechSplit
    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "split",
      type: type,
      songWriterSplit: value
    })
  }

  // helpers

  const explainLimit = limit => {
    const maxExplanation = 'Hmmm... Are you Adele?'
    const minExplanation = "Don't agree to less than 5% please"
    if(limit === 'max') setExplanation(maxExplanation)
    if(limit === 'min') setExplanation(minExplanation)
    setOpen(true)
    setTimeout(() => setOpen(false), 3000)
  }

  // material ui styles

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8'
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
  })

  const useStylesTooltip = makeStyles({
    tooltip: {
      // minWidth: 200,
      width: 200,
      backgroundColor: 'white',
      color: 'black',
      padding: 10
    }
  })

  const classes = useStyles()

  const classesTooltip = useStylesTooltip()

  // render

  return (
    <div className={`input-element ${className} bg-1 split`} id={`${type}-split`}>      
      <div className="flex-row space-between fixed-height-labels">
        <label className="label-1 split-heading">{label}</label>
        <button 
          className="button rate-button label-1"
          onClick={handleEdit}
        >
            edit rate
        </button>
      </div>      
      <Tooltip title={explanation} open={open} classes={classesTooltip} placement={'top'}>        
        <Slider
          // track={false}
          aria-labelledby="track-false-slider"
          getAriaValueText={valuetext}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={songwriterRoyalty}
          classes={classes}
          max={100}
          min={0}
        />
      </Tooltip>
      <div className="flex-row space-between split-labels">
        <span className="label-2"><span className="bold">Songwriter:</span> {songwriterRoyalty}%</span>
        <span className="label-2"><span className="bold">Publisher:</span> {publisherRoyalty}%</span>
      </div>
    </div>
  )
}

export default Split