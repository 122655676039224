import React from 'react'
import './index.css'
import SignUpButton from './sign-up-button'

const SignUpBlock = () => {
  return(
    <div id="sign-up-block" className="flex-row full-width space-evenly">
      <div className="column flex-center sign-up-cta">
        <label className="mb bold">Get your mechanical royalties</label>
        <SignUpButton href={'https://www.themlc.com/'} label="MLC" />
      </div>
      <div className="column flex-center sign-up-cta">
        <label className="mb bold">Get your performance royalties</label>
        <SignUpButton href={'https://createsafe.carrd.co/'} label="PRO" />  
      </div>      
    </div>
  )  
}

export default SignUpBlock