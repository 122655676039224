import { computeRecoupableShareColor, computeUnrecoupedOpacity } from "./helpers"

export const defaultStyles = { 
  root: {
    height: 40,
    borderRadius: 8,
    borderWidth: 0,
    // borderColor: '#7a7a7a',
    // borderStyle: 'solid',
    marginBottom: 12.5,
  },
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  bar: {
    // borderRadius: 5,
    backgroundColor: '#2FB380',
    transition: 'none',
  },
}

export const computeUnrecoupedStyles = (state, share) => {
  return  {
    root: {
    height: 40,
    borderRadius: 12,
    borderWidth: 0,
    // borderColor: '#7a7a7a',
    // borderStyle: 'solid',
    marginBottom: 12.5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    bar: {
      // borderRadius: 5,
      opacity: .75,
      backgroundColor: '#F8765F',
      transition: 'none',
      // zIndex: 99999
    },
  }
}

export const computeRecoupableStyles = (state, share) => {
  return {
    root: {
      height: 40,
      borderRadius: 12,
      borderWidth: 0,
      borderColor: 'black',
      borderStyle: 'none',
      marginBottom: 12.5,
    },
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    bar: {
      // borderRadius: 5,
      backgroundColor: '#2FB380',
      transition: 'none'
    },
  }
}