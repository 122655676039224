import React from 'react'
import NumberFormat from 'react-number-format'

import '../index.css'

const PillLabels = ({ config }) => {
  const { leftLabel, leftSublabel, leftValue, rightLabel, rightSublabel, rightValue, rightValueStyle } = config
  console.log('LEFT VALUE: ', leftValue)
  return (
    <div className="labels-row" id="recoup-labels">
      <div className="column flex-one lrc-1 resp-full-width">
        <label className="label-1 width-80 space" id="recoup-left-label">{leftLabel}&nbsp;</label>
        {leftSublabel && <p className="sublabel space no-resp">{leftSublabel}</p>}
        <NumberFormat value={leftValue} id="recoup-balance" displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
      </div>
      <div className="column right-label">
        <label className="label-1 space resp-no-margin">{rightLabel}</label>
        {rightSublabel && <p className="sublabel space">{rightSublabel}</p>}
        <NumberFormat style={rightValueStyle} decimalScale={0} value={rightValue} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      </div>
    </div>
  )
}

export default PillLabels