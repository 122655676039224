import React, { useState } from "react"
import { setGlobal, useEffect, useGlobal } from 'reactn'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import PageHeading from '../components/page-heading'
import PageDescription from '../components/page-description'
import Prompt from '../components/prompt'
import DealSimulator from '../components/deal-simulator'
import SharingIsCaring from '../components/sharing-is-caring'
import BottomOfPage from '../components/bottom-of-page'
import RequestResources from '../components/request-resources'
import Blob from '../components/blob'

import './index.css'
import SignUpBlock from "../components/sign-up-block"
import Banner from "../components/banner"
import Footer from "../components/footer"

const IndexPage = ({ location }) => {

  const [dealType, setDealType] = useGlobal("deal-type")
  const [songwriterPerfSplit, setSongwriterPerfSplit] = useGlobal("songwriter-perf-split")
  const [songwriterMechSplit, setSongwriterMechSplit] = useGlobal("songwriter-mech-split")
  const [adminFee, setAdminFee] = useGlobal("admin-fee")
  const [advance, setAdvance] = useGlobal('advance')
  const [recoupableAmount, setRecoupableAmount] = useGlobal('recoupable-amount')
  const [streams, setStreams] = useGlobal('streams')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')
  const [hasSubmitted] = useGlobal('hasSubmitted')
  const [mechRate, setMechRate] = useGlobal('mech-rate')
  const [perfRate, setPerfRate] = useGlobal('perf-rate')
  const [initialized, setInitialized] = useState(false)
  const [showResources, setShowResources] = useState(true)
  const [showFullPage, setshowFullPage] = useState(true)

  useEffect(() => {

    if(typeof window === 'undefined') return null
    
    const { location } = window

    const queryString = require('query-string')

    const parsed = queryString.parse(location.search)
    const queryObject = JSON.parse(JSON.stringify(parsed)) // create a js object so we can check using hasOwnProperty for keys

    const hasDealType = queryObject.hasOwnProperty('deal-type')
    const hasSongwriterPerfSplit = queryObject.hasOwnProperty('songwriter-perf-split')
    const hasSongwriterMechSplit = queryObject.hasOwnProperty('songwriter-mech-split')
    const hasAdvance = queryObject.hasOwnProperty('advance')
    const hasPerfStreamRate = queryObject.hasOwnProperty('perf-rate')
    const hasMechStreamRate = queryObject.hasOwnProperty('mech-rate')   
    const hasStreams = queryObject.hasOwnProperty('streams')
    const inApp = queryObject.hasOwnProperty('in-app')

    if(inApp) setshowFullPage(false)

    // make sure all parameters are in query

    if(hasDealType && hasSongwriterPerfSplit && hasSongwriterMechSplit && hasAdvance && 
    hasStreams && hasMechStreamRate && hasPerfStreamRate) 
      {

        // deal type

        if(queryObject["deal-type"] === 'admin') {          
          setDealType({
            label: 'Admin Deal',
            value: 'admin',
          })
        }
        if(queryObject["deal-type"] === 'copub') {
          setDealType({
            label: 'Co-Publishing Deal',
            value: 'copub',
          })        
        }
        if(queryObject["deal-type"] === 'self') {
          setDealType({
            label: 'Unpublished',
            value: 'self',
          })        
        }
        // royalties

        const royaltyAllowed = royalty => royalty >= 5 && royalty <= 95
        
        const qsPerfSplit = parseInt(queryObject["songwriter-perf-split"], 10)
        const qsMechSplit = parseInt(queryObject["songwriter-mech-split"], 10)

        if(royaltyAllowed(qsPerfSplit)) setSongwriterPerfSplit(queryObject["songwriter-perf-split"])
        if(royaltyAllowed(qsMechSplit)) setSongwriterMechSplit(queryObject["songwriter-mech-split"])

        // advance

        const advanceAllowed = v => v >= 0 && v <= 60000000

        const qsAdv = parseInt(queryObject.advance, 10)
        if(advanceAllowed(qsAdv)) setAdvance(qsAdv)

        // streams

        const defaultStreams = 0
        const defaultMaxStreams = 2000000000
        const qsStreams = parseInt(queryObject.streams, 10)

        if(qsStreams > 0 && qsStreams <= 100000000000){
          setMaxStreams(qsStreams * 2)
          setStreams(qsStreams)
        }
        else {
          setMaxStreams(defaultMaxStreams)
          setStreams(defaultStreams)
        }

        // streaming rates

        const defaultMechRate = .00059
        const defaultPerfRate = .00049

        const rateIsAllowed = rate => rate >= .0001 && rate <= .001
        
        // get rates given in query string 

        const qsMechRate = parseFloat(queryObject["mech-rate"], 10)
        const qsPerfRate = parseFloat(queryObject["perf-rate"], 10)

        if(rateIsAllowed(qsMechRate)) setMechRate(qsMechRate)
          else setMechRate(defaultMechRate)
        
        if(rateIsAllowed(qsPerfRate)) setPerfRate(qsPerfRate)
          else setPerfRate(defaultPerfRate)
      }
      else {
        setGlobal({
          "min-streams": 0,
          streams: 50000000,
          success: false,
          "max-streams": 100000000,
          income: 0,
          "max-gross": 0,
          "songwriter-perf-split": 100,
          "publisher-perf-split": 0,
          "songwriter-mech-split": 100,
          "publisher-mech-split": 0,
          "songwriter-profit": 0,
          "label-profit": 0,
          advance: 0,
          "recoupable-amount": 0,
          "perf-rate": .00049,
          "mech-rate": .00059,
          "admin-fee": 0,
          "deal-type": {
            label: 'Unpublished',
            value: 'self',
          },
        })
      }
    setInitialized(true)
  },[])

  useEffect(() => {
    if(hasSubmitted === true) setTimeout(() => handleSlideUp(), 2000)
  }, [hasSubmitted])

  useEffect(() => {
    const recoupable = parseInt(advance)
    setRecoupableAmount(recoupable)
    // console.log('RECOUP IS: ', recoupable)
    // console.log('ADVANCE IS: ', advance)
  }, [advance])

  const handleSlideUp = () => {
    setShowResources(false)
  }

  // initialize()

  if(!initialized) return null

  return(
    <Layout>
      <div id="index-page" className="flex column">
        <SEO title="Home" />
        { showFullPage && 
          <>
            <Banner />
            <Header />
            <Blob />
            <PageHeading />
            <PageDescription />
            <Prompt />
          </>
        }
        <div className={`flex column align-center`}>
          <div id="main" className="flex-row">
            <DealSimulator />
          </div>
          { dealType.value !== 'self' && <SharingIsCaring origin={location.origin} /> }
          { dealType.value === 'self' && showFullPage && <SignUpBlock /> }
          { showFullPage && <BottomOfPage /> }
        </div>
        { showFullPage && <Footer /> }
      </div>
    </Layout>
  )
}

export default IndexPage
