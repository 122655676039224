import React from 'react'
import './index.css'

const Tab = ({ title, description, active, onClick }) => ( 
  <div className={`deal-tab flex-center column ${active ? 'active-tab' : 'inactive-tab'}`} onClick={onClick}>
    <h1 className="deal-tab-label">{title}</h1>
    <span className="deal-tab-description">{description}</span>
  </div>
)

export default Tab