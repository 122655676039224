import React from 'react'

import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './index.css'

const PageDescription = () => {

  const description = 'Music publishing is confusing. We’ve created a publishing simulator for the streaming era to help you make sense of publishing income from streams. This tool also gives insight into how publishing deals work.'
  const href = "https://appliedscience.substack.com/p/publishing-simulator"
  const label = "Learn More"
  // main render

  return(
    <p id="simulator-description">
      {description}
      <br />
      <br />
      <OutboundLink 
        href={href}
        target="_new"
      >
        <button id="read-more-button" className="flex-center button white-button">{label}</button>
      </OutboundLink>
      <OutboundLink id="rds" href="https://dealsim.createos.app/" target="_new">Also check out the Record Deal Simulator!</OutboundLink>
    </p>
  )
}

export default PageDescription