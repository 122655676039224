import React from 'react'
import NumberFormat from 'react-number-format'
import { useGlobal } from 'reactn'
import { computeMoneyLeftOnTheTable } from '../revenue/math'
import './index.css'

const Callout = () => {  
  const [global] = useGlobal()
  return(
    <div id="callout" className="column flex-center">
      <label className="label-1 no-select">Unclaimed Money</label>
      <NumberFormat value={computeMoneyLeftOnTheTable(global)} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} className="big-number violent-red" />
    </div>
  )  
}

export default Callout