import React from 'react'
import { useGlobal } from 'reactn'

import './index.css'
import '../shared.css'

import NumberFormat from 'react-number-format'
import makeLabelConfig from './label-config'

const DealRespLabels = () => {
  const [global] = useGlobal()

  const labelConfig = makeLabelConfig(global)

  const labels = labelConfig.map(curr => {
    const { label, shareInDollars, classes } = curr
    
    return(
      <div className="column srl">
        <NumberFormat className="breakdown-number" value={shareInDollars} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className={`label-1 ${classes} deal-label no-select`}>{label}&nbsp;</label>  
      </div>
    )}
  )
  
  return(
    <div id="deal-resp-labels" className="column resp-only">
      {labels}
    </div>
  )  
}

export default DealRespLabels