import React from 'react'
import './index.css'
import '../index.css'

import Pill from './pill'
import PillLabels from './pill-labels'

const PillBlock = ({ pillConfig, labelConfig, className, id }) => (
  <div className={`border-bottom black-border recoup-block ${className}`} id={id}>
    <div style={{ position: 'relative' }}>
      { pillConfig.map(curr => <Pill config={curr}  />) }
      <PillLabels config={labelConfig} />
    </div>
  </div>
)

export default PillBlock