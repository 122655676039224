import { makeStyles } from '@material-ui/core'

export const defaultStyles = { 
  root: {
    height: 60,
    borderRadius: 100,
    borderWidth: 1,
    // borderColor: '#7a7a7a',
    // borderStyle: 'solid',
    marginBottom: 12.5,
  },
  colorPrimary: {
    backgroundColor: 'transparent',
  },
}

export const usePowderBlue = makeStyles({ ...defaultStyles, ...{ bar: { backgroundColor: '#93E5FF' } } })
export const usePillPurple = makeStyles({ ...defaultStyles, ...{ bar: { backgroundColor: '#B557FF' } } })
export const usePowderPurple = makeStyles({ ...defaultStyles, ...{ bar: { backgroundColor: '#CFA5EF' } } })
export const usePillBlue = makeStyles({ ...defaultStyles, ...{ bar: { backgroundColor: '#5669F0' } } })