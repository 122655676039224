import React from 'react'

import NumberFormat from 'react-number-format'

const Label = ({ id, label, shareInDollars, classes, shareAsPercentage, innerStyle, outerStyle, index, width, height }) => (
  <>
    <div className="column absolute flex-center ipl-container" id={id} style={innerStyle}>
      <NumberFormat className="inner-label" value={shareAsPercentage} displayType={'text'} decimalScale={1} thousandSeparator={true} suffix={'%'} />
    </div>
  </>
)

export default Label