import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import infoIcon from '../../../../images/info.png'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './index.css'

const tips = {
  copub: {
    heading: "Co-Publishing Deal",
    body: [<p>A Co-Publishing deal...</p>]
  },
  admin: {
    heading: "Admin Deal",
    body: [<p>Admin deals are...</p>]
  },
  advance: {
    heading: "Advance",
    body: [<p>An advance is almost always defined as a pre-payment of royalties, whether the advance is paid by a record company to an artist, a publisher to a writer or a merchandiser to an artist.</p>, <p>An advance is also sometimes called a “minimum guarantee.”</p>, <p>Advances in the music industry do not earn interest and are not loans. The advance is only “recoupable”—meaning that the advance is applied against earned royalties from the publishing deal.</p>]
  },
  "perf-rate": {
    heading: "Digital Performance Royalty Rate",
    body: [<p>Digital performance royalties must be paid to rights-holding songwriters and publishers when their songs are broadcast.</p>, <p>These royalties are paid by PROs (Performing Rights Organizations) who receive money from organizations in exchange for rights to broadcast music.</p>, <p>The performance or "perf" rate above reflects a fraction of a dollar paid per stream.</p>, <p>Rates are variable; our default is based on multiple data points.</p>, <p>While we used recent historic average rates for perf. and mech. royalties so that the simulator models current realities, we also want to make songwriters aware that <span className="bold">these rates can be negotiated.</span></p>]
  },
  "mech-rate": {
    heading: "Digital Mechanical Royalty Rate",
    body: [<p>Digital mechanical royalties must be paid to rights-holding songwriters and publishers when their songs are reproduced (through making digital copies).</p>, <p>The mechanical or "mech" rate above reflects a fraction of a dollar paid per stream.</p>, <p>Our default was based on average digital mech. rates paid by Spotify in 2020.</p>, <p>While we used recent historic average rates for perf. and mech. royalties so that the simulator models current realities, we also want to make songwriters aware that <span className="bold">these rates can be negotiated.</span></p>]
  },
  streams: {
    heading: "Streams",
    body: [<p>We have chosen to, for the moment, focus exclusively on revenue generated by <span className="bold">streaming royalties.</span></p>, <p>The reality is that only established songwriters and randomly extremely lucky people can make money from radio plays or syncs. However, in the new music industry, your average person <span className="bold">can</span> make money from streams.</p>]
  },
  "pro-profit": {
    heading: "PRO Profit",
    body: [<p>PROs (Performing Rights Organizations) sell rights to broadcast music to organizations wishing to do so.</p>, <p>PROs collect and pay out royalties on behalf of songwriters and pay them out according to their control of the compositions they register.</p>, <p>Admin fees vary depending on the PRO. In our model we used 16.5%, which would be typical.</p>]
  },
  "publisher-profit": {
    heading: "Publisher Profit",
    body: [<p>Publisher profit shown below only reflect profits that would be gained from {<span className="bold">streaming royalties.</span>}</p>, <p>It is important to note that streaming royalties <span className="italic">reflect only a portion of overall publisher profits</span> and that the bulk of publishers' revenue would be generated by radio plays and syncs.</p>]
  },
  "songwriter-profit": {
    heading: "Songwriter Profit",
    body: [<p>Songwriter profit shown below reflect profits that would be gained from streaming royalties, the main path to revenue generation for the vast majority of songwriters at present.</p>, <p>Typically only established songwriters and randomly extremely lucky people can make money from radio plays or syncs. However, in the new music industry, your average person <span className="bold">can</span> make money from streams.</p>]
  },
  "streaming-platforms": {
    heading: "Streaming Platforms",
    body: [<p>These are examples of streaming platforms, not an exhaustive list.</p>]
  }
}

const Info = ({ title }) => {

  // local state

  const [open, setOpen] = useState(false)

  const { heading, body } = tips[title]

  // handlers

  const handleClick = () => {
    if(open === false) {
      trackCustomEvent({
        category: "button",
        action: "click",
        label: `info-${title}`
      })
    }
    setOpen(currentIsOpen => !currentIsOpen)
  }

  // material ui styles

  const useStyles = makeStyles({
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      padding: 35,
      margin: 0,
      top: 10
    },
    arrow: {
      color: 'white'
    }
  })

  const classes = useStyles()
  
  return(
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip 
        open={open}
        interactive
        arrow
        classes={classes}
        TransitionProps={{ timeout: {
          enter: 500,
          exit: 200
        } }}
        title={
          <div className="tooltip-content">
            <h2 style={{ lineHeight: 1.1 }}>{heading}</h2>
            {body.map(curr => curr)}
          </div>
        }>  
        <img src={infoIcon} className="info-icon" onClick={handleClick} />
      </Tooltip>
    </ClickAwayListener>
  )
}

export default Info