import React from 'react'
import { useGlobal } from 'reactn'
import './index.css'

import DealTerms from './components/deal-terms'
import Revenue from './components/revenue'
import DealMenu from './components/deal-menu'
import DealBreakdownBlock from './components/breakdowns/deal-breakdown-block'
import DealSummary from './components/deal-summary'
import DspBlock from './components/logos/dsp-block'

const DealSimulator = () => {
  
  const [dealType] = useGlobal('deal-type')

  // render

  return (      
    <div id="simulator" className="flex-column white-text"> 
      <DealMenu />
      <div className="column full-width">        
        <DspBlock />
        <div className="flex-row full-width resp-column">
          { dealType.value !== 'self' && <DealTerms /> }
          <Revenue />  
        </div>              
      </div>      
      { dealType.value !== 'self' && <DealBreakdownBlock /> }
      { dealType.value !== 'self' && <DealSummary /> }
    </div>
  )
}

export default DealSimulator