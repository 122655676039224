import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import { db } from '../../firebase'
import * as EmailValidator from 'email-validator'
import Loader from 'react-loader-spinner'
// import fbTrack from '../../fb-track'
import GradientInput from '../deal-simulator/components/gradient-input'

import './index.css'
import 'react-phone-number-input/style.css'
import { ApplyModal } from '../deal-simulator/components/apply-modal'

const RequestResources = ({ bottom, banner }) => {

  // global state

  const [hasSubmitted, setHasSubmitted] = useGlobal('hasSubmitted')
  // global bc component is reused and we don't want it to reset 
  const [success, setSuccess] = useGlobal('success')

  // local state

  const [disabled, setDisabled] = useState(true) 
  const [message, setMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false)
  const [processing, setProcessing] = useState(false)  
  const [error, setError] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [formState, setFormState] = useState(
    { name: '', email: '', role: '', twitter: '', insta: '', company: '' }
  )

  // handle change of form state

  const handleChange = (e, key) => {
    const { value } = e.target
    console.log('name is: ', value)
    setFormState(prevState => (
      {
        ...prevState,
        [key]: value
      }
    ))
  }

  // effects

  useEffect(() => {
    const { name, email, role } = formState
    if(success === true || hasSubmitted === true) {
      setDisabled(true)
      setMessage("You're on the list!")
      setShowMessage(true)
      return
    }
    if(email === '' || name === '' || role === '') {
      setDisabled(true)
      if(role === '') setMessage('Please enter your role')
      if(email === '') setMessage('Please enter your email address')      
      if(name === '') setMessage('Please enter your name')
    }
    else {
      if(!EmailValidator.validate(email)) {
        setDisabled(true)
        setMessage('The email address is invalid')
      }
      else {
        setDisabled(false)
      }
    }
    setShowMessage(false)
  }, [formState])

  // firebase

  const addUserToWaitlist = async (mail) => {
    const { name, role, twitter, insta, company } = formState
    const date = new Date()
    const res = await db.collection("waitlist").doc(mail).set({
      name: name,
      email: mail,
      role: role,
      instagram: insta || 'n/a',
      twitter: twitter || 'n/a',
      company: company || 'n/a',
      source: 'pub-deal-sim',
      dateAdded: date.toLocaleDateString(),
    })
    .then(function() {
      console.log("Document written");
      return({ status: 'success' })
    })
    .catch(function(error) {
      console.error("Error adding document: ", error);
      return({ status: 'error', error: error })
    })
    return res
  }

  const handleSubmit = async () => { 
    if(disabled) {
      setShowMessage(true)
      return null
    }
    else {
      setProcessing(true)
    }
    const res = await addUserToWaitlist(formState.email)
    console.log(res)
    setProcessing(false)
    if(res.status === 'success') {
      setMessage("Success! We'll be in touch.")
      setShowMessage(true)
      setSuccess(true)
      // fbTrack('track', 'SubmitApplication')
      setHasSubmitted(true)
      setDisabled(true)
    }
    else setError(true)
    sendEmailToCreateSafe(formState.email)
  }

  // send an email to CS

  const sendEmailToCreateSafe = async (mail) => {
    const { name, role, twitter, insta, company } = formState
    const emailRes = await db.collection("mail").add({
      to: ['concierge@createsafe.io'],
      message: {
        subject: 'Pub Deal Sim waitlist request',
        text: `${name}, ${mail}, ${role}, ${twitter || 'n/a'}, ${insta || 'n/a'}, ${company || 'n/a'}, just requested to join the waitlist from the Pub Deal Sim page`,
      }
    })
    .then(() => {
      return ({ status: 'success' })
    })
    .catch(error => {
      console.log('error writing email', error)
      return { status: 'error', error: error }
    })
    console.log(emailRes)
  }

  // processing

  const Processing = () => {
    return(
      <Loader
        type="BallTriangle"
        color={"white"}
        height={30}
        width={30}
        timeout={30000} // 30 secs
    />
    )
  }

  // render

  let inputId = bottom ? 'email-input-bottom' : 'email-input'

  const inputs = Object.keys(formState).map((key, index) => (
    <GradientInput 
      placeholder={`${key[0].toUpperCase() + key.substring(1)} ${index < 3 ? '(required)' : ''}`}
      value={formState[key]}
      key={`${key}-key`}
      onChange={e => handleChange(e, key)}
      className="input bg-1 white-text"
      disabled={success}
      id={inputId}
      style={{ color: success && '#2FB380' }} 
    />
  ))

  return(
    <>
      <ApplyModal open={modalIsOpen} handleClose={() => setModalIsOpen(false)}>
        { !success 
            ?
              <>
                <h1 className="mb-2 kmb uppercase text-center">Get Early Access</h1>
                <div className="vm-half-rem" id="input-container">
                  {inputs}                  
                </div>
                <button style={{ paddingTop: processing && 5 }} id="submit-button" onClick={handleSubmit} className="margin-hz button">{ processing ? <Processing /> : 'Submit' }</button>
                <div id="cta-email-message">{showMessage ? message : ` `}</div>
              </>           
            :
              <h1>You're on the list.</h1>
        }
      </ApplyModal>
      { banner && <a href="https://createsafe.xyz/" target="_blank" rel="noreferrer" id="banner-button" className={`bg-trans button self-centered white-text bold padding full-width height-100 ${success && 'fade-out'}`}><span style={{ opacity: .6 }}>Click here for early access to </span>Create/OS! 🚀</a> }
    </>
  )
}

export default RequestResources