import React from 'react'
import { useGlobal } from 'reactn'
import RequestResources from '../../components/request-resources'

const Banner = () => {
  const [success] = useGlobal('success')
  return (
    <div className={`flex-row full-width flex-center bg-purple text-center ${success && 'fade-out bury'}`}>
      <RequestResources banner />
    </div>
  )
}

export default Banner