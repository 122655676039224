import React from 'react'
import { useGlobal } from 'reactn'

import { selfConfig, dealConfig } from './config'
import PillBlock from './pill-block'

const Blocks = ({ dealType }) => {
  const configs = {
    self: selfConfig,
    deal: dealConfig,
  }
  const [global] = useGlobal()
  const config = configs[dealType](global)
  return (
    config.map(curr => {
      const { pillConfig, labelConfig } = curr
      return <PillBlock pillConfig={pillConfig} labelConfig={labelConfig} />
    }) 
  ) 
}

export default Blocks