import React from 'react'
import Callout from './callout'
import LotDescription from './description'
import './index.css'

const LeftOnTheTable = () => {  
  return(
    <div id="lot" className="column flex-center">
      <Callout />
      <LotDescription />
    </div>
  )  
}

export default LeftOnTheTable