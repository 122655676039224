import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useGlobal } from 'reactn'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Info from '../info'

import './index.css'

const Debt = () => {

  // global state

  const [advance, setAdvance] = useGlobal('advance')
  const [recoupableAmount] = useGlobal('recoupable-amount')

  // local state

  const [advancedEdited, setAdvanceEdited] = useState(false)

  const advanceAllowed = v => {
    return (v >= 0 && v <= 60000000)
  }

  // change handlers

  const handleAdvanceChange = value => {
    if(value === '') setAdvance(0)
    else setAdvance(value)
    setAdvanceEdited(true)
  }

  // analytics

  const track = () => {
    trackCustomEvent({
      category: "input",
      action: "edit",
      label: "advance",
      value: advance
    })
  }

  // render

  return (
    <>
      <div className="column bg-1 " id="debt">
        <div className="mg-bt-hf flex-row align-center space-between" id="advance-box">
          <label className="label-1">Songwriter Advance</label>
          <Info title={'advance'} />
        </div>
        <NumberFormat 
          value={advance} decimalScale={0} thousandSeparator={true} 
          prefix={'$'} onValueChange={values => handleAdvanceChange(values.value)} 
          isAllowed={values => advanceAllowed(values.value)} className="debt-input" 
          onMouseUp={e => e.target.select()} onBlur={track}
        />        
        <p>This amount must be recouped by the songwriter</p>
      </div>
    </>
  )
}

export default Debt