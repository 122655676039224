import React from 'react'
import { useGlobal } from 'reactn'
import LinearProgress from '@material-ui/core/LinearProgress'
import { usePillPurple, usePowderBlue, usePowderPurple } from '../pill-styles'
import { getMechValue, getPerfValue } from '../helpers'

const BreakdownPill = () => {

  const [global] = useGlobal()

  const perfValue = getPerfValue(global)
  const mechValue = getMechValue(global)

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <LinearProgress
        value={100}
        variant="determinate"
        classes={usePillPurple()}        
        style={{ position: 'absolute', width: '100%' }}
      />
      <LinearProgress
        value={parseFloat(perfValue) + parseFloat(mechValue)} // add offset
        variant="determinate"
        classes={usePowderPurple()}
        style={{ position: 'absolute', width: '100%' }}
      />
      <LinearProgress
        value={mechValue}
        variant="determinate"
        classes={usePowderBlue()}
        style={{ position: 'relative' }}
      />
    </div>
  )
}

export default BreakdownPill