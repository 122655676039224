import React from 'react'
import Info from '../info'
import { dspLogos } from './index'
import './index.css'

const DspBlock = () => {  
  const keys = Object.keys(dspLogos)
  return(
    <div id="dsp-block" className="column">
      <div className="flex-row align-center" id="platform-label">
        <label className="label-1">digital streaming platforms</label>
        <div style={{ width: '.5rem' }} />
        <Info title="streaming-platforms" />
      </div>      
      <div className="flex-row space-between" id="dsp-logo-container">
        {keys.map(key => <img src={dspLogos[key]} className="dsp-logo" />)}
      </div>      
    </div>
  )  
}

export default DspBlock