import amazon from './images/amazon.png'
import apple from './images/apple.png'
import deezer from './images/deezer.png'
import spotify from './images/spotify.png'
import tidal from './images/tidal.png'
import youtube from './images/youtube.png'
import mlc from './images/mlc.png'
import bmi from './images/bmi.png'
import ascap from './images/ascap.png'

export const dspLogos = {
  amazon: amazon,
  apple: apple,
  deezer: deezer,
  spotify: spotify,
  tidal: tidal,
  youtube: youtube
}

export const royaltyLogos = {
  mlc: mlc,
  bmi: bmi,
  ascap: ascap,
}