import React, { useState } from 'react'
import { useEffect, useGlobal } from 'reactn'
import RateSlider from '../revenue/rate-slider'
import Debt from './debt'
import Split from './inputs/split'

const DealTerms = () => {

  const [dealType] = useGlobal('deal-type')

  // local state

  const [perfEditing, setPerfEditing] = useState(false)
  const [mechEditing, setMechEditing] = useState(false)

  // render

  return (
    <div className="column padding" id="deal-terms" style={{ width: '36%' }}>
      <>
        { !perfEditing && <Split type='perf' handleEdit={() => setPerfEditing(true)} /> }
        { perfEditing &&  <RateSlider type="perf" editable handleEdit={() => setPerfEditing(false)} /> }
        <div style={{ height: '.5rem' }} className="resp-space" />
        { !mechEditing && <Split type='mech' handleEdit={() => setMechEditing(true)} /> }
        { mechEditing && <RateSlider type="mech" editable handleEdit={() => setMechEditing(false)} /> }
      </>
      <div style={{ height: '.5rem' }} className="resp-space" />
      { dealType.value !== 'self' && <Debt /> }
    </div>
  )
}

export default DealTerms