import React from 'react'
import { useGlobal } from 'reactn'
import LinearProgress from '@material-ui/core/LinearProgress'
import { usePillBlue, usePillPurple, usePowderBlue, usePowderPurple } from '../pill-styles'
import { getPubSharePercentage, getSwSharePercentage, getSwProPercentage } from '../helpers'

const DealBreakdownPill = () => {

  const [global] = useGlobal()

  const swProPercentage = getSwProPercentage(global)
  const pubShare = getPubSharePercentage(global)
  const swSharePercentage = getSwSharePercentage(global)

  return (
    <div style={{ width: '100%', position: 'relative' }} id="deal-breakdown-pill">
      <LinearProgress
        value={100}
        variant="determinate"
        classes={usePillPurple()}        
        style={{ position: 'absolute', width: '100%' }}
      />
      <LinearProgress
        value={parseFloat(swProPercentage) + parseFloat(pubShare) + parseFloat(swSharePercentage)} // add offset
        variant="determinate"
        classes={usePowderPurple()}
        style={{ position: 'absolute', width: '100%' }}
      />
      <LinearProgress
        value={parseFloat(pubShare) + parseFloat(swSharePercentage)} // add offset
        variant="determinate"
        classes={usePillBlue()}
        style={{ position: 'absolute', width: '100%' }}
      />
      <LinearProgress
        value={swSharePercentage}
        variant="determinate"
        classes={usePowderBlue()}
        style={{ position: 'relative' }}
      />
    </div>
  )
}

export default DealBreakdownPill