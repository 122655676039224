import React, { useState } from 'react'
import { useGlobal } from 'reactn'
import link from '../../images/link.png'
import tweet from '../../images/tweet.png'
import { TwitterShareButton } from 'react-share'
import copy from 'copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip'
// import fbTrack from '../../fb-track'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './index.css'

const SharingIsCaring = ({ origin }) => {

  // global state

  const [global] = useGlobal()  

  // local state

  const [open, setOpen] = useState(false)

  // helpers

  const makeQueryString = () => {

    const cleanUpRate = rate => {
      const parsed = Number.parseFloat(rate)
      if(parsed === .0001 || parsed === .001) return parsed.toString()
      else return parsed.toFixed(5).toString()
    }


    const q = '?'
    const deal = `deal-type=${global["deal-type"].value}&`
    const songwriterPerfSplit = `songwriter-perf-split=${global["songwriter-perf-split"]}&`
    const songwriterMechSplit = `songwriter-mech-split=${global["songwriter-mech-split"]}&`
    const advance = `advance=${global.advance}&`
    const perfRate = `perf-rate=${cleanUpRate(global["perf-rate"])}&`
    const mechRate = `mech-rate=${cleanUpRate(global["mech-rate"])}&`
    const streams = `streams=${parseInt(global.streams)}`
    const queryString = origin + '/' + q + deal + songwriterPerfSplit + songwriterMechSplit 
    + advance + perfRate + mechRate + streams
    return queryString
  }

  const showConfirmation = () => {
    setOpen(true)
    setTimeout(() => setOpen(false), 1500)
  }

  const handleCopy = () => {
    const copyText = makeQueryString()
    copy(copyText)
    showConfirmation()
    

    trackCustomEvent({
      category: "button",
      action: "click",
      label: "copy-link"
    })
    // fbTrack('trackCustom', 'CopyLink')
  }

  const handleTweetClick = () => {
    trackCustomEvent({
      category: "button",
      action: "click",
      label: "tweet"
    })
    // fbTrack('trackCustom', 'ShareTwitter')
  }

  return(
    <div id="sharing-is-caring" className="flex-center self-centered column text-center">
      <div id="sic-content">
        <p>You can copy a link to share out your label deal or share directly to twitter.</p>
        <div className="flex-row space-between full-width" id="share-buttons">
          <Tooltip title="Copied!" open={open}>
            <button className="flex-center button" onClick={handleCopy}><img src={link} id="link-icon" />Copy Link</button>
          </Tooltip>
          <TwitterShareButton id="tweet-button" onClick={handleTweetClick} title={'I modeled my publishing using @CreateOS Publishing Simulator, check it out:'} hashtags={['PubSim', 'KnowYourself', 'KnowYourWorth' ]} url={makeQueryString()}>
            <button className="flex-center button"><img src={tweet} id="tweet-icon" />Tweet</button>
          </TwitterShareButton>
        </div>
      </div>
    </div>
  )
}

export default SharingIsCaring