import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'

import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'

import EditStreams from './edit-streams'
import { computeGross, computeAdminFee } from './math'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './stream-slider.css'

const StreamSlider = () => {

  // global state

  const [global] = useGlobal()
  const [streams, setStreams] = useGlobal('streams')
  const [dealType] = useGlobal('deal-type')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')
  const [gross, setGross] = useGlobal('gross')
  const [maxGross, setMaxGross] = useGlobal('max-gross')
  const [recoupableAmount] = useGlobal('recoupable-amount')
  const [adminFee, setAdminFee] = useGlobal('admin-fee')
  const [mechRate] = useGlobal('mech-rate')
  const [perfRate] = useGlobal('perf-rate')

  // effects

  useEffect(() => {
    setMaxGross(maxStreams * (mechRate + perfRate))
  }, [maxStreams, mechRate, perfRate])

  // material ui styles

  const fontLiteral = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`

  const getRadius = () => {
    if((gross / maxGross) > (99.5/100)) return 20
    else return 0
  }

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8',
      borderTopRightRadius: getRadius(),
      borderBottomRightRadius: getRadius()
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
    valueLabel: {
      color: 'transparent',
      fontSize: 28,
      fontWeight: 300,
      left: -7,
      fontFamily: fontLiteral,
      letterSpacing: 1.5
    },
    markLabel: {
      color: '#8f8f8f'
    },
  })

  const classes = useStyles()

  // effects

  useEffect(() => {
    setGross(computeGross(global))
    setAdminFee(computeAdminFee(global))
  }, [recoupableAmount, streams, mechRate, perfRate])

  const handleChange = (e, v) => {
    setStreams(v) // e is event v is value
  }

  const handleChangeCommitted = () => {
    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "streams",
      value: streams
    })
  }

  const getLabel = value => {
    let finalLabel
    const roundedStreams = Math.round((value + Number.EPSILON) * 100) / 100
    if(roundedStreams >= 1000000000) {
      const streamsInBillions = (roundedStreams / 1000000000)
      const roundedBillions = Number(Math.round((streamsInBillions) + "e" + 1) + "e-" + 1)
      const label = 'B'
      finalLabel = roundedBillions + label
      return finalLabel
    }
    if(roundedStreams >= 1000000) {
      const streamsInMillions = (roundedStreams / 1000000)
      const roundedMillions = Number(Math.round((streamsInMillions) + "e" + 1) + "e-" + 1)
      finalLabel = roundedMillions + 'M'
      return finalLabel
    }
    if(roundedStreams > 0) {
      const streamsInThousands = roundedStreams / 1000
      const roundedThousands = Number(Math.round((streamsInThousands) + "e" + 1) + "e-" + 1)
      const label = 'K'
      finalLabel = roundedThousands + label
      return finalLabel
    }
    return ''
  }

  const getStep = () => maxStreams / 100

  // marks for slider 

  const marks = [
    {
      value: 0,
      label: 0
    },
    {
      value: (maxStreams / 2),
      label: getLabel(maxStreams / 2)
    },
    {
      value: Math.round(maxStreams),
      label: getLabel(maxStreams)
    },
  ]

  // render

  return (
    <div id="stream-slider">
      <Slider
        getAriaValueText={getLabel}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        value={streams}
        max={maxStreams}
        min={0}
        valueLabelDisplay="on"
        valueLabelFormat={getLabel}
        classes={classes}
        step={getStep()}
        marks={marks}
      />      
    </div>
  )
}

export default StreamSlider