import React from 'react'
import { royaltyLogos } from './index'
import './index.css'

const RoyaltyBlock = () => {  
  const { mlc, bmi, ascap } = royaltyLogos
  return(
    <div id="royalty-block" className="flex-row space-between width-80 self-centered resp-column">
      <div className="column" id="dig-roy">
        <label className="label-1">Digital Mechanical Royalties</label>
        <label className="label-4 mb-hf">These royalties are paid to the MLC</label>      
        <img src={mlc} className="royalty-logo" />              
      </div>
      <div className="column" id="mech-roy">              
        <label className="label-1">Digital Performance Royalties</label>
        <label className="label-4 mb-hf">These royalties are paid to a PRO like BMI, ASCAP</label>      
        <div className="flex-row align-start">
          <img src={bmi} className="royalty-logo" />
          <div style={{ width: '.5rem' }} />
          <img src={ascap} className="royalty-logo" />
        </div>  
      </div>
    </div>
  )  
}

export default RoyaltyBlock