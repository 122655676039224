import React from 'react'

const GradientInput = (props) => {
  const { placeholder, value, onChange, className, id, disabled, style } = props
  return(
    <div id="input-gradient-wrapper" className="full-width mb">
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
        id={id}
        disabled={disabled}
        style={style}
      />   
    </div>
  )  
}

export default GradientInput