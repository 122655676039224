import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'

import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import Info from '../info'

import './index.css'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const RateSlider = ({ type, editable, handleEdit }) => {

  // global state

  const [perfRate, setPerfRate] = useGlobal('perf-rate')
  const [mechRate, setMechRate] = useGlobal('mech-rate')

  // helpers

  const transformSliderValueToRate = value => value / 10000
  const transformRateToSliderValue = value => value * 10000

  const getLabel = () => {
    const parsed = Number.parseFloat(transformSliderValueToRate(sliderValue))
    if(parsed !== .0001 && parsed !== .001) return parsed.toFixed(5)
    return parsed
  }

  const label = type === 'perf'
    ? <>Digital Perf.<br /> Rate</>
    : <>Digital Mech.<br /> Rate</>

  // initialize slider state

  const getValue = () => {
    if(type === 'mech') return transformRateToSliderValue(mechRate)
    if(type === 'perf') return transformRateToSliderValue(perfRate)
    if(type === 'dummy') return 0
  }

  const [sliderValue, setSliderValue] = useState(getValue())  

  // accessibility
  
  function valuetext(value) {
    return `${value}`;
  }

  // handlers

  const handleChange = (e, v) => {
    let value = v
    if(v <= 1) value = 1
    setSliderValue(value)
    if(type === 'perf') setPerfRate(transformSliderValueToRate(value))
    if(type === 'mech') setMechRate(transformSliderValueToRate(value))
  }

  const handleChangeCommitted = () => {
        
    const value = type === 'perf' ? perfRate : mechRate

    trackCustomEvent({
      category: "input",
      action: "slide",
      label: "rate",
      type: type,
      value: value
    })
  }

  // material ui styles

  const useStyles = makeStyles({
    rail: {
      color: '#e7e7e7',
      opacity: 1,
      height: 4
    },
    track: {
      height: 4,
      color: '#4156E8'
    },
    thumb: {
      color: '#e7e7e7',
      width: 20,
      height: 20,
      marginTop: -8,
      boxShadow: '0 0 5px #e7e7e7',
      '&:focus, &:hover, &$active': {
        boxShadow: '0 0 5px #e7e7e7',
      },
    },
  })

  const classes = useStyles()

  // render

  return (
    <div className="input-element bg-1" id="rate-slider">
      <div className="flex-row space-between label-container fixed-height-labels">
        <label className="label-1 rate-heading">{label}&nbsp;</label>
        { editable && (
            <button 
              className="button rate-button label-1"
              onClick={handleEdit}
              style={{ backgroundColor: 'white', color: 'black' }}
            >
              done
            </button>
          )
        }
        { !editable && <Info title={`${type}-rate`} /> }
      </div>
        <Slider
          // track={false}
          aria-labelledby="track-false-slider"
          getAriaValueText={valuetext}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={sliderValue}
          classes={classes}
          min={1}
          max={10}
          step={.1}
        />
      <div className={`flex-row space-between align-center ${!editable && 'pt-hf'} align-center"`} id="rate-label">
        <span className="label-2 bold">${getLabel()}</span>
        <span className="label-1 bold">&nbsp;PER STREAM</span>
      </div>
    </div>
  )
}

export default RateSlider