import React, { useState, useLayoutEffect } from 'react'
import { useGlobal } from 'reactn'
import './index.css'
import { computeMechGross, computePerfRoyaltyAfterAdminFee, computeProRevenue } from '../../revenue/math'
import NumberFormat from 'react-number-format'
import { getMechValue, getPerfValue, getVisibility } from '../helpers'

const BreakdownLabels = () => {
  const [global] = useGlobal()

  const perfValue = getPerfValue(global)
  const mechValue = getMechValue(global)

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, []);
    return size
  }

  const [width, height] = useWindowSize()
  
  return(
    <div id="breakdown-labels" className="flex-row absolute width-80" key={width.toString() + height.toString()}>
      {/* MECHANICAL */}
      <div className="column absolute flex-center ipl-container" id="mechanical-label" style={{ zIndex: 1, width: `${mechValue}%`, visibility: getVisibility('mechanical-label') }}>
        <NumberFormat className="inner-label" value={mechValue} displayType={'text'} decimalScale={1} thousandSeparator={true} suffix={'%'} />
      </div>
      <div className="column absolute bd-label-top no-resp">
        <NumberFormat className="breakdown-number" value={computeMechGross(global)} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className="label-1 powder-blue deal-label">Digital Mechanical Royalty&nbsp;</label>  
      </div>
      {/* PERFORMANCE */}
      <div className="column absolute flex-center ipl-container" id="performance-label" style={{ zIndex: 1, width: `${perfValue}%`, left: `${mechValue}%`, visibility: getVisibility('performance-label') }}>
        <NumberFormat className="inner-label" value={perfValue} displayType={'text'} decimalScale={1} thousandSeparator={true} suffix={'%'} />
      </div>
      <div className="column bd-label-bottom no-resp" style={{ left: `${mechValue}%`, position: 'absolute'}}>
        <NumberFormat className="breakdown-number" value={computePerfRoyaltyAfterAdminFee(global)} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className="label-1 powder-purple deal-label">Digital Performance Royalty&nbsp;</label>
      </div>
      {/* PRO */}
      <div className="column absolute flex-center ipl-container" id="pro-label" style={
          { 
            zIndex: 1, 
            width: `${100 - (parseFloat(perfValue) + parseFloat(mechValue))}%`, 
            left: `${parseFloat(perfValue) + parseFloat(mechValue)}%`, 
            visibility: getVisibility('pro-label')
          }
      }>
        <NumberFormat className="inner-label" value={100 - (parseFloat(perfValue) + parseFloat(mechValue))} displayType={'text'} decimalScale={1} thousandSeparator={true} suffix={'%'} />
      </div>
      <div className="column bd-label-top no-resp"  style={{ left: `${parseFloat(perfValue) + parseFloat(mechValue)}%`, position: 'absolute' }}>
        <NumberFormat className="breakdown-number" value={computeProRevenue(global)} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className="label-1 pill-purple deal-label">Amount Retained by PRO</label>
      </div>
    </div>
  )  
}

export default BreakdownLabels