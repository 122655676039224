import { OutboundLink } from 'gatsby-plugin-google-analytics'
import React from 'react'
import './index.css'

const SignUpButton = ({ href, label }) => {  
  return(
    <OutboundLink href={href} target="_new">
      <button className="flex-center button white-button">{label} Sign Up</button>
    </OutboundLink>    
  )  
}

export default SignUpButton