import React from 'react'
import './index.css'

const LotDescription = () => {  
  const description = 'If you are unpublished and aren’t yet signed up with an administrator (e.g. MLC) and a PRO (e.g. ASCAP), the amount above is the total share that the songwriter(s) are leaving on the table.'
  return(
    <div id="lot-description">
      <span>
        {description}
      </span>
    </div>
  )  
}

export default LotDescription