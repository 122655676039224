import React from 'react'

import './index.css'

import DealBreakdownLabels from './labels'
import DealBreakdownPill from './pill'
import DealRespLabels from './deal-resp-labels'
import DealNoRespLabels from './no-resp-labels'

const DealBreakdownBlock = () => {
  return(
    <div id="deal-breakdown-block" className="column width-80">      
      <DealBreakdownLabels />
      <DealBreakdownPill />
      <DealRespLabels />
      <DealNoRespLabels />
    </div>
  )  
}

export default DealBreakdownBlock