import React from 'react'
import csLogo from '../../images/cs-logo-black.svg'
import github from '../../images/github.svg'
import instagram from '../../images/instagram.svg'
import linkedin from '../../images/linkedin.svg'
import soundcloud from '../../images/soundcloud.svg'
import medium from '../../images/medium.svg'
import discord from '../../images/discord.svg'

import './index.css'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Footer = () => {
  const logos = [
    { 
      logo: instagram,
      url: 'https://www.instagram.com/createsafe.io/',
    },
    { 
      logo: linkedin,
      url: 'https://www.linkedin.com/company/createsafe/',
    },
    { 
      logo: medium,
      url: 'https://medium.com/createsafe',
    },
    { 
      logo: github,
      url: 'https://github.com/createsafe',
    },
    {
      logo: discord,
      url: 'https://discord.com/invite/Mm3mucx'
    },
    { 
      logo: soundcloud,
      url: 'https://soundcloud.com/createsafe',
    }
  ]
    
  return(
    <div id="footer" className="full-width align-center bg-white black-text flex-row">
      <div id="left-foot" className="flex-row space-between width-50">
        <OutboundLink href="https://createsafe.io/" target="_new">
          <img src={csLogo} id="cs-logo-foot" />
        </OutboundLink>
        <div className="column">
          <label className="weight-800 label-1">Resources</label>
          <OutboundLink href="https://dealsim.createos.app/" target="_new">
            <span className="label-1">record deal simulator</span>
          </OutboundLink>
          <a href="https://pubsim.createos.app/">
            <span className="label-1">publishing simulator</span>            
          </a>
        </div>
        <div className="column">
          <label className="weight-800 label-1">Company</label>
          <OutboundLink href="https://createsafe.io/our-mission" target="_new">
            <span className="label-1">Our Mission</span>
          </OutboundLink>
          <OutboundLink href="https://services.createsafe.io/" target="_new">
            <span className="label-1">Services</span>
          </OutboundLink>
        </div>
      </div>
      <div id="right-foot" className="flex-row space-between width-50">
        <div className="flex-row uppercase resp-column">
          <div className="flex-row flex-center" id="footer-logos">
            {logos.map(logo => 
              <OutboundLink href={logo.url} target="_new" className="footer-logo-link">
                <img src={logo.logo} className="footer-logo" />
              </OutboundLink> 
            )}
          </div>
          <span style={{ marginLeft: '1rem '}}>© createsafe 2021</span>
        </div>
      </div>
    </div>
  )
}

export default Footer