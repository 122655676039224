import React, { useState, useEffect } from 'react'
import { useGlobal } from 'reactn'
import Tab from './tab'

const DealMenu = () => {  
  const [dealType, setDealType] = useGlobal('deal-type')
  const [advance, setAdvance] = useGlobal('advance')
  const [songwriterPerfSplit, setSongwriterPerfSplit] = useGlobal('songwriter-perf-split')
  const [songwriterMechSplit, setSongwriterMechSplit] = useGlobal('songwriter-mech-split')
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    const type = dealType.value
    type === 'self' && setTabIndex(0)
    type === 'copub' && setTabIndex(1)
    type === 'admin' && setTabIndex(2)
  }, [])
  
  const deals = [
    {
      index: 0,
      value: 'self',
      label: 'Unpublished',
      nick: 'Unpublished',
      description: 'No deal with a publisher or administrator',
      copyrights: 'All royalties and rights belong to the songwriter(s)',
      defaults: {
        perfSplit: 100,
        mechSplit: 100,
        advance: 0
      }      
    },
    {      
      index: 1,
      value: 'copub',
      label: 'Co-Publishing Deal',
      nick: 'Co-Publishing',
      description: 'A deal in which a publisher co-owns your pub catalog',
      defaults: {
        perfSplit: 50,
        mechSplit: 75,
        advance: 50000
      }      
    },
    {
      index: 2,
      value: 'admin',
      label: 'Admin Deal',
      nick: 'Admin',
      description: 'A deal in which a company collects royalties without owning copyright',
      defaults: {
        perfSplit: 80,
        mechSplit: 75,
        advance: 50000
      }
    }
  ]
  
  const handleTabClick = deal => {
    setTabIndex(deal.index)
    setDealType(deal)
    setSongwriterPerfSplit(deal.defaults.perfSplit)
    setSongwriterMechSplit(deal.defaults.mechSplit)
    setAdvance(deal.defaults.advance)
  }
  const tabs = deals.map(deal =>
    <Tab 
      title={deal.nick} 
      description={deal.description} 
      active={tabIndex === deal.index} 
      onClick={() => handleTabClick(deal)}
    />
  )

  return(
    <div className="full-width column mb-2" id="deal-menu">
      <h5>Select a deal type:</h5>
      {/* {deals[tabIndex].copyrights && <p>{deals[tabIndex].copyrights}</p>} */}
      <div className="flex-row my-2 space-between" id="tabs-container">
        {tabs}
      </div>
    </div>
  )
}

export default DealMenu