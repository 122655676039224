import { computeMechPortionOfRoyalty, computePerfPortionOfRoyaltyAfterAdminFee, computeProShareOfGross, computePublisherShareOfGross, computeSongwriterTotalRecoupableShareOfGross, computeWritingShareOfGross } from "../revenue/math";

export const isOverflown = ({ scrollWidth, clientWidth, scrollHeight, clientHeight }) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
}

export const getVisibility = element => {  
  const el = document.getElementById(element)
  if(el === null) return 'visible'
  const elementOverflown = isOverflown(el)
  if(elementOverflown) return 'hidden'
  return 'visible'
}

export const parseValue = value => parseFloat(value * 100).toFixed(1)

export const getPerfValue = state => (
  parseFloat(computePerfPortionOfRoyaltyAfterAdminFee(state) * 100).toFixed(1)
)

export const getMechValue = state => {
  console.log('STATE IS: ', state)
  return parseFloat(computeMechPortionOfRoyalty(state) * 100).toFixed(1)
}

export const getSwSharePercentage = state => (
  parseValue(computeSongwriterTotalRecoupableShareOfGross(state))
)

export const getPubSharePercentage = state => (
  parseValue(computePublisherShareOfGross(state))
)

export const getSwProPercentage = state => (
  parseValue(computeWritingShareOfGross(state))
) 

export const getProPercentage = state => (
  parseValue(computeProShareOfGross(state))
) 