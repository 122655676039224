import React from 'react'
import { useGlobal } from 'reactn'

import './index.css'
import '../shared.css'

import NumberFormat from 'react-number-format'
import makeLabelConfig from './label-config'

const DealNoRespLabels = () => {
  const [global] = useGlobal()

  const labelConfig = makeLabelConfig(global)

  const labels = labelConfig.map(curr => {
    const { label, shareInDollars, classes } = curr
    
    return(
      <div className="column srl">
        <NumberFormat className="breakdown-number" value={shareInDollars} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className={`label-1 ${classes} no-select deal-label`}>{label}&nbsp;</label>  
      </div>
    )}
  )
  
  return(
    <div id="deal-no-resp-labels" className="self-centered flex-row space-between no-resp width-90">
      <div className="column">
        {[labels[0], labels[2]]}
      </div>
      <div className="column">
        {[labels[1], labels[3]]}
      </div>
    </div>
  )  
}

export default DealNoRespLabels