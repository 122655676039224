import React from 'react'
import { useGlobal } from 'reactn'
import { computeStreamsToRecoup } from '../components/revenue/math'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import './index.css'

const RecoupDeal = () => {

  // global state

  const [global] = useGlobal()
  const [dealType] = useGlobal('deal-type')
  const [advance] = useGlobal('advance')
  const [streams, setStreams] = useGlobal('streams')
  const [maxStreams, setMaxStreams] = useGlobal('max-streams')

  const recoup = () => {
    
    trackCustomEvent({
      category: "button",
      action: "click",
      label: "recoup"
    })

    if(parseInt(advance) === 0) return
    const gettingEven = Math.ceil(computeStreamsToRecoup(global))
    console.log('GETTING EVEN: ', gettingEven)
    setStreams(gettingEven)
    setMaxStreams(gettingEven * 2)
  }

  if(dealType.value === 'self') return <div />

  return <button onClick={recoup} id="recoup-deal-button" className="margin-hz button label-1 full-width mb-hf">Recoup Deal</button>
}

export default RecoupDeal