import React from 'react'
import NumberFormat from 'react-number-format'
import Info from '../info'

import './index.css'

const SummaryItem = ({ party, profitValue }) => (
  <div className="summary-item">
    <div className="flex-row">
      <label className="label-1 mr-half">{party} profit:</label>
      <Info title={`${party}-profit`} />  
    </div>
    <NumberFormat 
      className="big-number" 
      value={profitValue} 
      displayType={'text'} 
      decimalScale={0} 
      thousandSeparator={true} 
      prefix="$" 
      style={{ color: 'white' }} 
    />
  </div>
)  

export default SummaryItem