import React from 'react'
import { 
  computeMechGross,
  computeMechPortionOfRoyalty, 
  computeWritingShareOfGross,
  computeWritingRevenue,
  computeProRevenue,
  computeProShareOfGross,
  computeSongwriterTotalRecoupableShareOfGross,
  computeSongwriterRecoupableRevenue,
  computeSongwriterRecouped,
  computePublisherRevenue,
  computeRecoupableAmount,
  computePublisherRecouped,
  computePerfPortionOfRoyaltyAfterAdminFee,
  computePerfRoyaltyAfterAdminFee,
  computeGross,
  computePublisherPublishingShare,
  computePublisherShareOfGross,
  computeRecoupment,
  computeSongwriterPublisherShare
} from '../math'
import { computeRecoupedWidth, computeWidth, getLabel, getLabelColor, getRecoupmentStatus } from './helpers'
import { defaultStyles, computeRecoupableStyles, computeUnrecoupedStyles } from './styles'

export const selfConfig = state => {
  const digitialPerfBlock = makeDigitialPerfBlock(state)
  const digitalMechBlock = makeDigitalMechBlock(state)
  const proBlock = makeProBlock(state)
  const blocks = [digitalMechBlock, digitialPerfBlock, proBlock]
  return blocks
}

export const dealConfig = state => {
  const songwriterWritingBlock = makeSongwriterWritingBlock(state)
  // const songwriterRecoupableBlock = makeSongwriterRecoupableBlock(state)
  const songwriterPublishingDealRoyaltyBlock = makeSongwriterPublishingDealRoyaltyBlock(state)
  const coPublisherBlock = makeCoPublisherBlock(state)
  const recoupmentBlock = makeRecoupmentBlock(state)
  const proBlock = makeProBlock(state)
  const blocks = [recoupmentBlock]
  return blocks
}

const makeDigitialPerfBlock = state => {
  const percentOfGross = parseFloat(computePerfPortionOfRoyaltyAfterAdminFee(state) * 100).toFixed(1)
  return {
    labelConfig: {
      leftLabel: 'Digital Performance Royalty',
      leftValue: computePerfRoyaltyAfterAdminFee(state),
      rightLabel: `${percentOfGross}% of gross`
    },
    pillConfig: [{
      value: computeWidth(state, computePerfRoyaltyAfterAdminFee(state)),
      muiStyles: defaultStyles,
      position: 'relative',
    }],
  }
}

const makeDigitalMechBlock = state => {
  const percentOfGross = parseFloat(computeMechPortionOfRoyalty(state) * 100).toFixed(1)
  return {
    labelConfig: {
      leftLabel: 'Digital Mechanical Royalty',
      leftValue: computeMechGross(state),
      rightLabel: `${percentOfGross}% of gross`
    },
    pillConfig: [{
      value: computeWidth(state, computeMechGross(state)),
      muiStyles: defaultStyles,
      position: 'relative',
    }], 
  }     
}

const makeSongwriterWritingBlock = state => {
  const percentOfGross = parseFloat(computeWritingShareOfGross(state) * 100).toFixed(1)
  return {
    labelConfig: {
      leftLabel: 'Songwriter’s direct royalty from pro',
      leftValue: computeWritingRevenue(state),
      rightLabel: `${percentOfGross}% of gross`
    },
    pillConfig: [{
      value: computeWidth(state, computeWritingRevenue(state)),
      muiStyles: defaultStyles,
      position: 'relative',
    }], 
  }     
}

const makeSongwriterPublishingDealRoyaltyBlock = state => {
  const percentOfGross = parseFloat(computeSongwriterTotalRecoupableShareOfGross(state) * 100).toFixed(1)
  return {
    labelConfig: {
      leftLabel: 'Songwriter’s Royalty in Publishing Deal',
      leftValue: computeSongwriterPublisherShare(state),
      rightLabel: `${percentOfGross}% of gross`,
      rightSublabel: `Goes toward artist’s balance until deal is recouped.`
    },
    pillConfig: [{
      value: computeWidth(state, computeSongwriterPublisherShare(state)),
      muiStyles: defaultStyles,
      position: 'relative',
    }], 
  }     
}

const makeRecoupmentBlock = state => {
  const percentOfGross = parseInt(computeSongwriterTotalRecoupableShareOfGross(state) * 100)
  return {
    labelConfig: {
      leftLabel: 'Balance on Advance',
      leftSublabel: `Comes out of Writer's royalty in publishing deal.`,
      leftValue: computeRecoupment(state),
      // rightLabel: `${percentOfGross}% of gross`, // move somewhere else
      // rightLabel: `Songwriter Publishing ${getLabel(computeSongwriterRecouped(state))}`, // move somewhere else
      rightLabel: getRecoupmentStatus(computeRecoupment(state)),
      rightValueStyle: { color: getLabelColor(computeRecoupment(state)) }
    },
    pillConfig: [
      {
        value: 100,
        muiStyles: computeUnrecoupedStyles(state, computeSongwriterRecoupableRevenue(state)),
        position: 'absolute',
      },
      {
        value: computeRecoupedWidth(state, computeRecoupment(state)),
        muiStyles: computeRecoupableStyles(state, computeSongwriterRecoupableRevenue(state)),
        position: 'relative',
      },
    ] 
  }     
}

const makeProBlock = state => {
  const percentOfGross = parseFloat(computeProShareOfGross(state) * 100).toFixed(1)
  return {
    labelConfig: {
      leftLabel: 'amount retained by PRO',
      leftValue: computeProRevenue(state),
      rightLabel: `${percentOfGross}% of gross`
    },
    pillConfig: [{
      value: computeWidth(state, computeProRevenue(state)),
      muiStyles: defaultStyles,
      position: 'relative',
    }], 
  }     
}

// const makeCoPublisherBlock = state => {
//   // NEED TO ADD A SECOND PILL ABSOLUTE FOR RECOUPMENT TO THIS BLOCK
//   const percentOfGross = parseInt(computePublisherShareOfGross(state).toFixed(2) * 100)
//   return {
//     labelConfig: {
//       leftLabel: 'Co-publisher Share',
//       leftValue: computePublisherRevenue(state),
//       rightLabel: `${percentOfGross}% of gross`
//     },
//     pillConfig: [
//       {
//         value: computeWidth(state, computeRecoupableAmount(state), 'recoup'),
//         muiStyles: computeUnrecoupedStyles(state, computePublisherRevenue(state)),
//         position: 'absolute',
//       },
//       {
//         value: computeWidth(state, computePublisherRevenue(state)),
//         muiStyles: computeRecoupableStyles(state, computePublisherRevenue(state)),
//         position: 'relative',
//       }
//     ], 
//   }     
// }

const makeCoPublisherBlock = state => {
  const percentOfGross = parseFloat(computePublisherShareOfGross(state) * 100).toFixed(1)
  return {
    labelConfig: {
      leftLabel: 'music publisher / administrator’s share',
      leftValue: computePublisherRevenue(state),
      rightLabel: `${percentOfGross}% of gross`, // move somewhere else
      // rightLabel: `Publisher ${getLabel(computePublisherRecouped(state), 'pub')}`, // move somewhere else
      // rightValue: parseInt(Math.abs(computePublisherRecouped(state))),
      // rightValueStyle: { color: getLabelColor(computePublisherRecouped(state)) }
    },
    pillConfig: [
      {
        value: computeWidth(state, computePublisherRevenue(state)),
        muiStyles: defaultStyles,
        position: 'relative',
      }
    ], 
  }     
}