import React from 'react'
import './index.css'
import '../shared.css'

import BreakdownLabels from './labels'
import BreakdownPill from './pill'
import SelfRespLabels from './self-resp-labels'

const BreakdownBlock = () => {
  return(
    <div id="self-pub-breakdown-block" className="breakdown-block column my-2 width-80 self-centered">      
      <BreakdownLabels />
      <BreakdownPill />
      <SelfRespLabels />
    </div>
  )  
}

export default BreakdownBlock