import React from 'react'
import { useGlobal } from 'reactn'
import './index.css'

import Info from '../../info'
import EditStreams from '../edit-streams'
import StreamSlider from '../stream-slider'
import SliderSummary from '../slider-summary'

const StreamsBlock = () => {
  const [dealType] = useGlobal('deal-type')
  return (
    <div id="streams-block" className="bg-1 height-100 flex-column space-between">          
      <div className="flex-row space-between" id="streams-top-section">
        <div className="mb-3">
          <div className="flex-row full-width align-center" id="slider-label">
            <label className="label-1">Streams</label>
            <div style={{ width: '.5rem' }} />
            <Info title="streams" />
          </div>          
          <span className="margin-0 grey-text" id="streams-across-platforms">This represents streams across all platforms</span>                    
        </div>        
        { dealType.value !== 'self' && (
            <div className="column no-resp">
              <EditStreams column />
            </div>
          )
        }
      </div>      
      <StreamSlider />
      { dealType.value !== 'self' && (
          <div className="column resp-only">
            <EditStreams column />
          </div>
        )
      }
      <SliderSummary />
    </div>
  ) 
}

export default StreamsBlock