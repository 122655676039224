import React from 'react'
import { useGlobal } from 'reactn'

import RevenueHeading from './revenue-heading'
import StreamSlider from './stream-slider'
import PublisherDisplayBar from './publisher-display-bar'
import BottomSummary from './bottom-summary'
import ProBar from './pro-bar'
import Info from '../info'

// import SongwriterPublishingBar from './songwriter-publishing-bar'
import SongwriterWritingBar from './songwriter-writing-bar'
import Blocks from './newStuff/blocks'
import StreamsBlock from './streams-block'
import RateSlider from './rate-slider'
// import DspBlock from '../logos/dsp-block'
import ArrowsBlock from '../logos/arrows'
import LeftOnTheTable from '../left-on-the-table'
import BreakdownBlock from '../breakdowns/breakdown-block'
import RoyaltyBlock from '../logos/royalty-block'
import DealBreakdownBlock from '../breakdowns/deal-breakdown-block'
import DspBlock from '../logos/dsp-block'

const Revenue = () => {

  const [global] = useGlobal()
  const [dealType] = useGlobal('deal-type')

  // render

  return (
    <div className="column flex-one" id="revenue">
        { 
          dealType.value !== 'self'
            ?
              <>                
                <StreamsBlock />                
              </>
            : 
              <>
                <div className="flex-row resp-column" id="self-deal-container">
                  <div className="mr-half flex-one column resp-no-margin" >
                    <StreamsBlock />
                  </div>
                  <div className="column justify-end" id="rate-container">
                    <RateSlider type='mech' />
                    <div style={{ height: '.5rem' }} className="resp-space"/>                    
                    <RateSlider type='perf' />
                  </div>                
                </div>
                {/* <div className="flex column flex-center my-2 width-80 self-centered resp-full-width resp-black resp-no-margin">                   */}
                  {/* <ArrowsBlock /> */}                  
                  <RoyaltyBlock />
                {/* </div>                 */}
              </>
        }
        {/* </div> */}

      {/* { dealType.value === 'self' && <Blocks dealType={'self'} /> } */}
      { dealType.value === 'self' && <BreakdownBlock /> }      
      { (dealType.value === 'admin' || dealType.value === 'copub') && <Blocks dealType={'deal'} /> }
      {/* <SongwriterPublishingBar /> */}
      {/* <SongwriterWritingBar /> */}
      {/* { dealType.value !== 'self' && <PublisherDisplayBar /> }
      { dealType.value !== 'self' && <ProBar /> } */}
      {/* { dealType.value !== 'self' && <BottomSummary /> } */}
      { dealType.value === 'self' && <LeftOnTheTable />}      
    </div>
  )
}

export default Revenue