import React from 'react'
import { useGlobal } from 'reactn'
import { computeProRevenue, computePublisherProfit, computeSongwriterProfit } from '../revenue/math'

import './index.css'

import SummaryItem from './summary-item'

const DealSummary = () => {
  const [global] = useGlobal()
  return(
    <div id="deal-summary" className="resp-column">
      <SummaryItem party="songwriter" profitValue={computeSongwriterProfit(global)} />
      <SummaryItem party="publisher" profitValue={computePublisherProfit(global)} />
      <SummaryItem party="pro" profitValue={computeProRevenue(global)} />
    </div>
  )  
}

export default DealSummary