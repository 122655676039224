import React, { useLayoutEffect, useState } from 'react'
import { useGlobal } from 'reactn'
import './index.css'
import '../shared.css'

import { getVisibility } from '../helpers'
import makeLabelConfig from './label-config'
import Label from './label'

const DealBreakdownLabels = () => {
  const [global] = useGlobal()

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, []);
    return size
  }

  const [width, height] = useWindowSize()
  
  const labelConfig = makeLabelConfig(global)

  const labels = labelConfig.map((curr, index) => {
    const { id, label, shareInDollars, shareAsPercentage, left, classes } = curr
    const innerStyle = {
      zIndex: 1, width: `${shareAsPercentage}%`, visibility: getVisibility(id), left: left
    }
    const outerStyle = {
      left: left, 
    }
    return (
      <Label 
        id={id} label={label} shareInDollars={shareInDollars} 
        shareAsPercentage={shareAsPercentage} left={left} classes={classes}
        outerStyle={outerStyle} innerStyle={innerStyle} index={index} 
        width={width} height={height}
      />
    )}
  )
  
  return(
    <div id="deal-breakdown-labels" className="breakdown-labels flex-row absolute width-80">
      {labels}
    </div>
  )  
}

export default DealBreakdownLabels