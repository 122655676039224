import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core'
import NumberFormat from 'react-number-format'

const Pill = ({ config }) => {

  const { value, muiStyles, position, width } = config

  const useStyles = makeStyles(muiStyles)
  const classes = useStyles()

  return (
    <div style={{ width: '100%', position: position }}>
      <LinearProgress
        value={value} // fix
        variant="determinate"
        classes={classes}
      />
    </div>
  )
}

export default Pill