
const adminFeeAsDecimal = .165

const takeAdminFee = value => value * (1 - adminFeeAsDecimal)

export const computeRevenuePerStream = state => state["perf-rate"] + state["mech-rate"]

export const computePerfPortionOfRoyalty = state => state["perf-rate"] / computeRevenuePerStream(state)

export const computePerfPortionOfRoyaltyAfterAdminFee = state => {
  return takeAdminFee(computePerfPortionOfRoyalty(state))
}

export const computePerfRoyaltyAfterAdminFee = state => {
  return computePerfPortionOfRoyaltyAfterAdminFee(state) * computeGross(state)
}

export const computeMechPortionOfRoyalty = state => state["mech-rate"] / computeRevenuePerStream(state)

export const computeMechRoyalty = state => {
  return computeMechPortionOfRoyalty(state) * computeGross(state)
}

export const computeGross = state => computeMechGross(state) + computePerfGross(state)



export const computeWritingShareOfGross = state => {
  const perfPortionOfRoyalty = computePerfPortionOfRoyalty(state)
  const perfPortionOfRoyaltyLessAdminFee = takeAdminFee(perfPortionOfRoyalty)  
  const writingShare = perfPortionOfRoyaltyLessAdminFee * .5
  return writingShare
}

export const computePerfPublishingShareOfGross = state => {
  return computeWritingShareOfGross(state) // they are the same bc 50/50
}

export const computeProShareOfGross = state => {
  return computePerfPortionOfRoyalty(state) * adminFeeAsDecimal // they are the same bc 50/50
}


export const computeProRevenue = state => {
  return computeProShareOfGross(state) * computeGross(state) // they are the same bc 50/50
}

export const computeWritingRevenue = state => {
  const gross = computeGross(state)
  const writingShareOfGross = computeWritingShareOfGross(state)
  const writingRevenue = gross * writingShareOfGross
  return writingRevenue
}

export const computePerfPublishingRevenue = state => {
  return computeWritingRevenue(state) // they are the same bc 50/50 split
}

export const computeSongwriterPerfPublishingShareOfGross = state => {
  return computePerfPublishingShareOfGross(state) * computeSplitAsDecimal(state["songwriter-perf-split"])
}

export const computeSongwriterMechShareOfGross = state => {
  return computeMechPortionOfRoyalty(state) * computeSplitAsDecimal(state["songwriter-mech-split"])
}

export const computeSongwriterTotalRecoupableShareOfGross = state => {
  const m = computeSongwriterMechShareOfGross(state)
  const p = computeSongwriterPerfPublishingShareOfGross(state)
  return m + p
}

export const computeSongwriterRecoupableRevenue = state => {
  return computeSongwriterTotalRecoupableShareOfGross(state) * computeGross(state)
}

export const computePublisherMechShareOfGross = state => {
  return computeMechPortionOfRoyalty(state) * computeSplitAsDecimal(state["publisher-mech-split"])
}

export const computePublisherPerfShareOfGross = state => {
  return computePerfPublishingShareOfGross(state) * computeSplitAsDecimal(state["publisher-perf-split"])
}

export const computePublisherShareOfGross = state => {
  const m = computePublisherMechShareOfGross(state)
  const p = computePublisherPerfShareOfGross(state)
  return m + p
}

export const computePublisherRevenue = state => { // rename to co-publisher
  return computePublisherShareOfGross(state) * computeGross(state)
}

// at present, the only recoupable cost is the advance
export const computeRecoupableAmount = state => parseInt(state["advance"])

export const computeSongwriterRecouped = state => {
  const rr = computeSongwriterRecoupableRevenue(state)
  return rr - computeRecoupableAmount(state)
}

export const computeRecoupment = state => {
  const rr = computeSongwriterRecoupableRevenue(state)
  const recouped = rr - computeRecoupableAmount(state)
  if(recouped >= 0) return 0
  return recouped
}

export const computePublisherRecouped = state => {
  const pr = computePublisherRevenue(state)
  return pr - computeRecoupableAmount(state)
}

export const computeSongwriterProfit = state => {
  // const dt = state["deal-type"].value
  // if(dt === 'self') return computeGross(state)
  const wr = computeWritingRevenue(state)
  const rp = computeSongwriterRecouped(state)
  const profit = wr + Math.max(rp, 0)
  return profit
}

export const computePublisherProfit = state => {
  const r = computePublisherRevenue(state)
  const recoupment = computeRecoupment(state)
  const profit = Math.max((r + recoupment), 0)
  return profit
}

export const computeMoneyLeftOnTheTable = state => {
  const mr = computeMechRoyalty(state)
  const pr = computePerfRoyaltyAfterAdminFee(state)
  return mr + pr
}

export const computeSongwriterPublisherShare = state => {
  console.log('sw publisher share: ', typeof Math.max(computeSongwriterRecouped(state), 0), Math.max(computeSongwriterRecouped(state)))
  return Math.max(computeSongwriterRecouped(state), 0)
}

export const computeStreamsToRecoup= state => {
  const ra = state["advance"]
  const rs = computeSongwriterTotalRecoupableShareOfGross(state) // recoupable share
  const rps = computeRevenuePerStream(state)
  const necessaryStreamsToBreakEven = ra / (rps * rs)
  console.log('necessary to break even', typeof necessaryStreamsToBreakEven, necessaryStreamsToBreakEven)
  return necessaryStreamsToBreakEven
  // const necessaryStreamsToBreakEven = necessaryGrossToBreakEven / totalRate
  // const numberOfStreamsEqualToOneDollar = 1 / totalRate
  // return necessaryStreamsToBreakEven + (numberOfStreamsEqualToOneDollar / trueRoyalty)
}





export const computeSongwriterPerfPublishingRevenue = state => {
  return computeSongwriterPerfPublishingShareOfGross(state) * computeGross(state)
}

// publishing share of gross = 1/2

export const computePublishingShare = state => computeGross(state) * .5

// total gross performance royalties

export const computePerfGross = state => state["perf-rate"] * state.streams

// total gross mechanichal royalties

export const computeMechGross = state => state["mech-rate"] * state.streams

// publishing share of performance revenue

export const computePerfPublishingShare = state => computePerfGross(state) * .5

export const computeMechPublishingShare = state => computeMechGross(state) * .5

export const computePerfWritingShare = state => computePerfGross(state) * .5

export const computeMechWritingShare = state => computeMechGross(state) * .5


// a fixed fee of 16.5% 
// comes off the top of publishing allocation of perf royalty in the event of there being a co-publisher

export const computeAdminFee = state => {
  return state["deal-type"].value === 'self' ? 0 : computePerfGross(state) * .165 
}

// split is kept as an integer that corresponds to a percentage in 100

export const computeSplitAsDecimal = split => split / 100



export const computeSongwriterWritingRoyalty = state => (computePerfGross(state) - computeAdminFee(state)) * .5 // or if there is a division other than 50/50

export const computePerfShareablePublishingRoyalty = state => (computePerfPublishingShare(state) - computeAdminFee(state)) * .5 // or if there is a division other than 50/50

export const computePerfShare = (state, split) => {
  return computeSplitAsDecimal(split) * computePerfShareablePublishingRoyalty(state)
}

export const computeMechShare = (state, split) => {
  return computeSplitAsDecimal(split) * computeMechGross(state)
}

export const computeSongwriterPerfPublishingShare = state => computePerfShare(state, state["songwriter-perf-split"])
  
export const computePublisherPerfPublishingShare = state => computePerfShare(state, state["publisher-perf-split"])

export const computeSongwriterPublishingShare = state => {
  const songwriterPerfPublishingShare = computeSongwriterPerfPublishingShare(state)
  const songwriterMechShare = computeMechShare(state, state["songwriter-mech-split"])
  return songwriterPerfPublishingShare + songwriterMechShare
}

export const computePublisherPublishingShare = state => {
  const publisherPerfShare = computePerfShare(state, state["publisher-perf-split"])
  const publisherMechShare = computeMechShare(state, state["publisher-mech-split"])
  return publisherPerfShare + publisherMechShare
}

export const computeShare = (state, party) => {
  if(party === 'songwriter') return computeSongwriterPublishingShare(state) + computeSongwriterWritingRoyalty(state)
  if(party === 'publisher') return computePublisherPublishingShare(state)
}

export const computeProfit = (state, party) => {
  const recoupableAmount = state["recoupable-amount"]
  let profit
  if(party === 'publisher') {
    const share = computeShare(state, party)
    profit = share - recoupableAmount
  }
  if(party === 'songwriter') {
    const publishingShare = computeSongwriterPerfPublishingShare(state)
    let publishingProfit = Math.max(0, publishingShare - recoupableAmount)
    profit = computeSongwriterWritingRoyalty(state) + publishingProfit
  }
  if(profit <= 0) return 0
  else return profit
}

export const computeUnrecouped = (state, party) => {
  const recoupableAmount = state["recoupable-amount"]
  let unrecouped
  if(party === 'publisher') {
    const share = computeShare(state, party)
    unrecouped = share - recoupableAmount
  }
  if(party === 'songwriter') {
    const publishingShare = computeSongwriterPerfPublishingShare(state)
    unrecouped = publishingShare - recoupableAmount
  }
  if(unrecouped >= 0) return 0
  else return unrecouped
}

export const computeTrueRoyalty = state => {
  const perfRateLessAdminFee = state["perf-rate"] * (1 - .165)
  const songwriterPerfRate = perfRateLessAdminFee * computeSplitAsDecimal(state["songwriter-perf-split"])
  const songwriterMechRate = state["mech-rate"] * computeSplitAsDecimal(state["songwriter-mech-split"])
  const songwriterTotalRate = songwriterPerfRate + songwriterMechRate
  const totalRate = state["perf-rate"] + state["mech-rate"] 
  const trueRoyalty = songwriterTotalRate / totalRate
  return trueRoyalty
}

export const computeStreamsToMakeOneDollar = state => {
  const recoupableAmount = state["recoupable-amount"]
  const trueRoyalty = computeTrueRoyalty(state)
  const totalRate = computeTotalRate(state)
  const necessaryGrossToBreakEven = recoupableAmount / trueRoyalty
  const necessaryStreamsToBreakEven = necessaryGrossToBreakEven / totalRate
  const numberOfStreamsEqualToOneDollar = 1 / totalRate
  return necessaryStreamsToBreakEven + (numberOfStreamsEqualToOneDollar / trueRoyalty)
}

export const computeTotalRate = state => state["perf-rate"] + state["mech-rate"]