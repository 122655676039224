import React from 'react'
import { useGlobal } from 'reactn'
import './index.css'
import { computeMechGross, computePerfRoyaltyAfterAdminFee, computeProRevenue } from '../../revenue/math'
import NumberFormat from 'react-number-format'

const SelfRespLabels = () => {
  const [global] = useGlobal()
  
  return(
    <div id="self-resp-labels" className="column resp-only">
      {/* MECHANICAL */}
      <div className="column srl">
        <NumberFormat className="breakdown-number" value={computeMechGross(global)} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className="label-1 powder-blue deal-label">Digital Mechanical Royalty&nbsp;</label>  
      </div>
      {/* PERFORMANCE */}
      <div className="column srl">
        <NumberFormat className="breakdown-number" value={computePerfRoyaltyAfterAdminFee(global)} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className="label-1 powder-purple deal-label">Digital Performance Royalty&nbsp;</label>
      </div>
      {/* PRO */}
      <div className="column srl">
        <NumberFormat className="breakdown-number" value={computeProRevenue(global)} displayType={'text'} decimalScale={0} thousandSeparator={true} prefix={'$'} />
        <label className="label-1 pill-purple deal-label">Amount Retained by PRO</label>
      </div>
    </div>
  )  
}

export default SelfRespLabels