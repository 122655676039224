import { computeProRevenue, computePublisherRevenue, computeSongwriterPublisherShare, computeWritingRevenue } from "../../revenue/math"
import { getProPercentage, getPubSharePercentage, getSwProPercentage, getSwSharePercentage } from "../helpers"

const makeLabelConfig = state => {
  const swSharePercentage = getSwSharePercentage(state)
  const pubSharePercentage = getPubSharePercentage(state)
  const swProPercentage = getSwProPercentage(state)
  const proPercentage = getProPercentage(state)
  return [
    {
      id: "sw-royalty-label",
      classes: 'powder-blue',
      label: 'Songwriter’s Royalty in Publishing Deal',
      shareInDollars: computeSongwriterPublisherShare(state),
      shareAsPercentage: swSharePercentage,
      left: 0
    },
    {
      id: "pub-label",
      classes: 'pill-blue',
      label: 'music publisher / administrator’s share',
      shareInDollars: computePublisherRevenue(state),
      shareAsPercentage: pubSharePercentage,
      left: `${swSharePercentage}%`
    },
    {
      id: "sw-pro-label",
      classes: 'powder-purple',
      label: 'Songwriter’s direct royalty from pro',
      shareInDollars: computeWritingRevenue(state),
      shareAsPercentage: swProPercentage,
      left: `${parseFloat(swSharePercentage) + parseFloat(pubSharePercentage)}%`
    },
    {
      id: "pro-label",
      classes: 'pill-purple',
      label: 'amount retained by PRO',
      shareInDollars: computeProRevenue(state),
      shareAsPercentage: proPercentage,
      left: `${parseFloat(swSharePercentage) + parseFloat(pubSharePercentage) + parseFloat(swProPercentage)}%`
    },
  ]
}

export default makeLabelConfig