import React from 'react'
import { computeRecoupableAmount, computeRecoupment, computeRevenuePerStream, computeSongwriterRecoupableRevenue } from '../math'

// export const getColor = () => {
//   if(unrecoupedAmount < .5 && profit < .5) return '#2196f3'
//   if(profit > 0) return '#52f9b8'
//   if(profit === 0) return '#FF8974'
// }

// export const getLabel = () => {
//   if(unrecoupedAmount < .5 && profit < .5) return 'is Even'
//   if(unrecoupedAmount > 0) return 'Unrecouped'
//   return 'Publishing profit:'
// }
export const getLabel = (value, pub) => {
  if(value === 0) return 'Is Even'
  if(value > 0) return 'Profit'
  if(value < 0) return pub === 'pub' ? 'Unrecovered' : 'Unrecouped'
} 

export const computeRecoupableShareColor = (state, share) => {
  const recoupable = computeRecoupableAmount(state)
  if(share > recoupable) return '#2FB380'
  return '#F8765F'
} 

export const getLabelColor = value => {
  if(parseInt(value) === 0) return '#2196f3'
  if(value > 0) return '#52f9b8'
  if(value < 0) return '#ff8974'
} 

export const computeUnrecoupedOpacity = (state, share) => {
  const recoupable = computeRecoupableAmount(state)
  if(share > recoupable) return 1
  return .5
} 

export const computeMaxSliderGross = state => {
  return state["max-streams"] * computeRevenuePerStream(state)
}

export const computeWidth = (state, value, max, debug) => {
  // console.log('THE VALUE IS: ', value)
  // console.log('THE MAX GROSS IS: ', computeMaxSliderGross(state))
  // console.log('THE type is: ', debug)
  // console.log('THE Returned Width is: ', value / computeMaxSliderGross(state) * 100)
  let maximum = computeMaxSliderGross(state)
  if(max) maximum = max
  const width = value / maximum * 100
  if(width > 100) return 100
  return width
}

export const computeRecoupedWidth = (state, value) => {
  if(parseInt(state.advance) === 0) return 100 
  const ra = computeRecoupableAmount(state)
  const x = ra + value
  const percentage = x / ra
  const width = percentage * 100
  return width
}

export const getRecoupmentStatus = value => {
  if(value < 0) return <>Deal Status: <div style={{ color: '#ff8974' }} className="status">Unrecouped</div></>
  return <>Deal Status: <div style={{ color: '#2196f3' }} className="status ">Recouped</div></>
}

// export const computeMechPercentageOfGross = state => {
//   return parseInt((computeMechPortionOfRoyalty(state).toFixed(2) * 100))
// }